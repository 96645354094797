import React, { useEffect } from 'react';
import './Aboutme.css'; // Importing custom CSS for complex styles

function Aboutme() {
    useEffect(() => {
        const aboutmeSection = document.querySelector('.aboutme');
        console.log('Aboutme section styles before:', aboutmeSection.style.opacity, aboutmeSection.style.transform);
        aboutmeSection.style.opacity = 1;
        aboutmeSection.style.transform = 'translateY(0)';
        console.log('Aboutme section styles after:', aboutmeSection.style.opacity, aboutmeSection.style.transform);
    }, []);
    return (
        <section className="aboutme">
          <div id="aboutme" className="aboutme-content">
            <h1>About Me</h1>
            <p>Hello there, I'm a completely self-taught full stack developer from Wales. As far as technology goes, I mainly create my web applications in ROR (Ruby on Rails) and ReactJS depending on the complexity of the project. I almost always use tailwindcss for my styling unless a client specifies otherwise. For storing data I usually use PostgreSQL but might sometimes use MongoDB if i need something done quickly. As a person i consider myself to be quite a hard worker when it comes to things i care about. Like most people i have several goals i plan to achieve in the near future, the main goal being to launch a successful startup.</p>
            <div className="flex justify-center space-x-8 mt-4">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Ruby_On_Rails_Logo.svg/2560px-Ruby_On_Rails_Logo.svg.png" alt="Ruby on Rails" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" alt="React" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/2560px-Tailwind_CSS_Logo.svg.png" alt="Tailwind CSS" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/993px-Postgresql_elephant.svg.png" alt="PostgreSQL" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png" alt="MongoDB" className="h-10" />
            </div>
          </div>
        </section>
      );
}
export default Aboutme;
